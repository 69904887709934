import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout/index";
import SEO from "../components/seo";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy | GivFast" />
      <div className="relative py-16 overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <article>
            <div className="text-lg max-w-prose mx-auto mb-6 prose">
              <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                <FormattedMessage id="title_pp_page" />
              </h1>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_1" /> 3/31/2020
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_1" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_2" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_3" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_4" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_5" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_6" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_7" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_2" />
              </h2>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_3" />
              </h2>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_8_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_8_2" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_4" />
              </h2>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_9_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_9_2" />{" "}
                <a href="https://www.digitalocean.com/legal/data-security/"></a>
                <a href="https://www.digitalocean.com/legal/data-security/">
                  https://www.digitalocean.com/legal/data-security/
                </a>{" "}
                <FormattedMessage id="pp_p_9_3" />{" "}
                <a href="https://www.digitalocean.com/legal/certifications/">
                  https://www.digitalocean.com/legal/certifications/
                </a>{" "}
                <FormattedMessage id="pp_p_9_4" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_10" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_5" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_11" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_6" />
              </h2>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_12_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_12_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_13_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_13_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_14_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_14_2" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_7" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_15" />
              </p>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_1" />
                </li>
              </ul>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_2" />
                </li>
              </ul>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_8" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_16" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_17" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_18" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_19" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_20" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_21_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_21_2" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_9" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_22" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_23" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="pp_p_24_1" />
                </strong>{" "}
                <FormattedMessage id="pp_p_24_2" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_25" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_26" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_27" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_10" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_28" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_29" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_30" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_31" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_32" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_33" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_11" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_34" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_35" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_36" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_37" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_12" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_38" />
              </p>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_3" />
                </li>
              </ul>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_4" />
                </li>
              </ul>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_5" />
                </li>
              </ul>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_6" />
                </li>
              </ul>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_13" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_39" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_14" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_40" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_15" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_41" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_16" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_42" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_17" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_43" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_18" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_44" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_45" />
              </p>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_7" />
                </li>
              </ul>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_8" />
                </li>
              </ul>
              <ul className="text-base">
                <li>
                  <FormattedMessage id="pp_l_9" />
                </li>
              </ul>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_46" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_19" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_47" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_48" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_20" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_49" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_50" />
              </p>
              <p className="my-2 text-base">
                • <FormattedMessage id="pp_p_51" />
              </p>
              <p className="my-2 text-base">
                • <FormattedMessage id="pp_p_52" />
              </p>
              <p className="my-2 text-base">
                • <FormattedMessage id="pp_p_53" />
              </p>
              <p className="my-2 text-base">
                • <FormattedMessage id="pp_p_54" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_55" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_56" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_21" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_57" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_58" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_59" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_22" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_60" />
              </p>
              <h2 className="my-4 font-bold">
                <FormattedMessage id="pp_h_23" />
              </h2>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_61" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_62" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="pp_p_63_1" />{" "}
                <a className="hover:underline" href="mailto:office@givfast.com">
                  office@givfast.com
                </a>
                , <FormattedMessage id="pp_p_63_2" />
              </p>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
